






















































































































































































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import FailedPayments from "@/components/payments/FailedPayments.vue";
import PaymentsTable from "@/components/payments/PaymentsTable.vue";
import ModalContainer from "@/components/ModalContainer.vue";
import { Payment } from "@/types";
import { Loan } from "@/types";
import { BIconCheckCircleFill } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { Md5 } from "ts-md5/dist/esm";
import HorizontalBarChart from "@/components/chart/HorizontalBarChart.vue";
import i18n from "@/i18n";

export default Vue.extend({
  name: "Payments",
  props: {
    loanId: { required: false, default: null },
  },

  components: {
    PageHeading,
    PaymentsTable,
    FailedPayments,
    ModalContainer,
    BIconCheckCircleFill,
    HorizontalBarChart,
  },
  data: () => ({
    paymentLink: "",
    showButton: false,
    bulkAmount: null,
    today: new Date(),
    finalDate: "",
    possibleProps: [
      "loan_start_date",
      "loan_end_date",
      "totalamount",
      "outstanding",
      "arrears",
    ] as unknown as (keyof Loan)[],
    chartDataAll: {},
    singleChartData: {},
    totalLenght: 0,

    horizontalChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            let label = data.datasets[tooltipItem.datasetIndex].label;
            let value = data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ].toLocaleString("de-De", { minimumFractionDigits: 2 });
            return " " + label + ": \u20AC " + value;
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            // gridLines: {
            //   display: false,
            // },
            ticks: {
              maxTicksLimit: 2,
              beginAtZero: true,
              max: 0,
              min: 0,
              callback: function (value: number) {
                return (
                  "\u20AC " +
                  value.toLocaleString("de-De", { minimumFractionDigits: 2 })
                );
              },
            },
            "dataset.maxBarThickness": 2,
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    fetched: false,
  }),
  created() {
    if (this.loanId !== null) {
      this.$store.commit("loans/setSelectedLoanId", this.loanId);
      this.$store.dispatch("payments/fetchSingleLoanPayments").then(() => {
        this.checkLoanId();
      });
    } else {
      this.$store.dispatch("payments/fetchSingleLoanPayments").then(() => {
        this.checkLoanId();
      });
    }
  },
  mounted() {
    if (this.$route.query.f === "t") {
      this.$bvModal.show("modal-twikey");
      this.checkLoanId();
    }
  },
  computed: {
    ...mapGetters({
      payments: "payments/getPayments",
      loans: "loans/getLoans",
    }),
    selectedLoan: {
      get(): number | null {
        return this.$store.getters["loans/selectedLoanId"];
      },
      set(value: number) {
        this.$store.commit("loans/setSelectedLoanId", value);
      },
    },
    loan(): Loan {
      if (this.selectedLoan !== null) {
        return this.loans.filter(
          (l: Loan) => l.ac_deal_id === this.selectedLoan
        )[0];
      } else {
        return this.loans[0];
      }
    },
    paymentsFunc(): Payment[] {
      return this.payments.filter(
        (p: Payment) => p.application_id == this.loan.application_id
      );
    },
    loansIds(): Loan {
      let loansForSelect = this.loans.map((l: Loan) => {
        return { value: l.ac_deal_id, text: l.application_id }; // TODO: put something more meaningfull for the end-user
      });
      return loansForSelect;
    },
    failedPayments(): Payment[] {
      /**
       * Get single Loan selected by the filter
       */
      const selectedLoan = this.loans.find(
        (loan: Loan) => loan.ac_deal_id == this.selectedLoan
      );

      /**
       * @return FailedPayment array
       */
      return this.$store.state.payments.payments.filter((p: Payment) => {
        const loanId = this.loans.find(
          (loan: Loan) => loan.application_id == p.application_id
        );
        if (
          p.application_id == selectedLoan.application_id &&
          loanId.repayed < loanId.totalamount
        ) {
          return !["Confirmed", "Pending"].includes(p.payment_status);
        }
      });
    },
  },
  methods: {
    exporToSpreadsheet() {
      this.$store.dispatch("payments/export");
    },
    getMostRecentLoan() {
      const currentTime = Date.now();
      let diff = this.loans.map((l: Loan) => {
        return Math.abs(currentTime - Number(l.loan_start_date));
      });

      let i = diff.indexOf(Math.min(...diff));

      if (i == -1) {
        i = this.loans.length - 1;
      }

      return this.loans[i].ac_deal_id;
    },
    executePayment(payment: Payment) {
      /*
       * CREATING A FORMATE DATE TO USE WITH APPLICATION ID
       *
       ** ex: 25120231559
       * 25 Day, 1 Month, 2023 Year, 15 Hour, 59 Minutes
       *
       */
      this.finalDate =
        this.today.getDate() +
        "" +
        (this.today.getMonth() + 1) +
        "" +
        this.today.getFullYear() +
        "" +
        this.today.getHours() +
        "" +
        this.today.getMinutes();

      if (this.loan.application_id) {
        this.$store
          .dispatch("loans/executePayment", {
            paymentId: payment.md5_hash,
            amount: payment.amount,
            email: this.$store.state.auth.user.email,
            contactId: this.$store.state.auth.user.id,
            description:
              this.loan.application_id +
              "CP" +
              this.finalDate +
              " " +
              payment.md5_hash,
            application_id: this.loan.application_id,
            ac_deal_id: this.loan.ac_deal_id,
          })
          .then((res: any) => {
            this.paymentLink = res.url;
            this.$bvModal.show("modal-payment");
          })
          .catch((e: any) => console.log(e));
      } else {
        this.$store
          .dispatch("loans/executePayment", {
            paymentId: payment.md5_hash,
            amount: payment.amount,
            email: this.$store.state.auth.user.email,
            contactId: this.$store.state.auth.user.id,
            description:
              payment.application_id +
              "CP" +
              this.finalDate +
              " " +
              payment.md5_hash,
            application_id: payment.application_id,
            ac_deal_id: this.loan.ac_deal_id,
          })
          .then((res: any) => {
            this.paymentLink = res.url;
            this.$bvModal.show("modal-payment");
          })
          .catch((e: any) => console.log(e));
      }
    },
    executePaymentInBulk() {
      /*
       * CREATING A FORMATE DATE TO USE WITH APPLICATION ID
       *
       ** ex: 25120231559
       * 25 Day, 1 Month, 2023 Year, 15 Hour, 59 Minutes
       *
       */
      this.finalDate =
        this.today.getDate() +
        "" +
        (this.today.getMonth() + 1) +
        "" +
        this.today.getFullYear() +
        "" +
        this.today.getHours() +
        "" +
        this.today.getMinutes();

      const paymentId = Md5.hashStr(
        this.loan.application_id + "T" + this.finalDate
      );

      this.$store
        .dispatch("loans/executePayment", {
          paymentId: paymentId,
          amount: this.loan.arrears,
          email: this.$store.state.auth.user.email,
          contactId: this.$store.state.auth.user.id,
          description: this.loan.application_id + "CPB" + this.finalDate,
          application_id: this.loan.application_id,
          ac_deal_id: this.loan.ac_deal_id,
        })
        .then((res: any) => {
          this.paymentLink = res.url;
          this.$bvModal.show("modal-payment");
        })
        .catch((e: any) => console.log(e));
    },
    checkLoanId() {
      if (
        this.selectedLoan !== null &&
        this.loan.repayed < this.loan.totalamount &&
        this.loan.arrears > 0
      ) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    singleLoanDataChart() {
      if (this.selectedLoan !== null) {
        const singleLoan = this.loans.filter(
          (l: Loan) => l.ac_deal_id === this.selectedLoan
        )[0];

        this.horizontalChartOptions.scales.xAxes[0].ticks.max =
          singleLoan.totalamount;

        return {
          datasets: [
            {
              label: i18n.t("loansPage.LoanDetails.already-paid"),
              backgroundColor: "#58c259",
              data: [singleLoan.repayed],
            },
            {
              label: i18n.t("loansPage.LoanDetails.arrears"),
              backgroundColor: "#f0120e",
              data: [singleLoan.arrears],
            },
            {
              label: i18n.t("loansPage.LoanDetails.outstanding"),
              backgroundColor: "#f2b20f",
              data: [singleLoan.outstanding - singleLoan.arrears],
            },
          ],
        };
      }
    },
    resetUrl(app_id: any) {
      if (this.$route.path.split("/payments")[1] !== "" && app_id !== null) {
        this.$router.replace("/payments/" + app_id);
      } else if (
        this.$route.path.split("/payments")[1] !== "" &&
        app_id === null
      ) {
        this.$router.replace("/payments");
      }
    },
  },
});
