
























































































import Vue from "vue";
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import { Loan, Payment, TableField } from "@/types";
import { localizeDate } from "@/utils/helpers";

export default Vue.extend({
  name: "PaymentsTable",
  props: {
    loanId: { required: false, default: null },
    selectedLoan: { required: false, default: null },
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    loanSelected: null,
    paymentLink: "",
  }),
  watch: {
    loanId: {
      handler: function () {
        this.loanSelected = this.loanId;
      },
      immediate: true,
    },
    selectedLoan: {
      handler: function () {
        this.loanSelected = this.selectedLoan;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      payments: "payments/getPaymentsWithLoanData",
      loans: "loans/getLoans",
    }),
    fields(): TableField[] {
      // Accessing this.$i18n.locale in this computed prop tells Vue that
      // this computed prop should update whenever this.$i18n.locale
      // changes it value, even though we don't use the value.
      const lang = this.$i18n.locale;
      // We add the following just so that minification optimizers
      // do not remove the above statement because the return value isn't used.
      if (!lang) {
        return [];
      }
      return [
        {
          key: "payment_date",
          label: i18n.t("paymentsPage.paymentsTable.collection-date"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
        {
          key: "amount",
          label: i18n.t("paymentsPage.paymentsTable.amount"),
        },
        {
          key: "payment_status",
          label: i18n.t("paymentsPage.paymentsTable.status"),
          sortable: true,
          thClass: "b-table-sort-icon-left",
        },
      ];
    },
    totalRows(): number {
      return this.payments.length;
    },

    filteredPayments(): Payment[] {
      if (this.loanSelected === null) {
        return [];
      } else {
        // get application_id of selected loan
        const selectedLoan = this.loans.find(
          (loan: Loan) => loan.ac_deal_id == this.loanSelected
        );
        return this.payments.filter(
          (payment: Payment) =>
            payment.application_id == selectedLoan.application_id
        );
      }
    },

    filteredPaymentsLenght(): number[] {
      if (this.loanSelected === null) {
        return this.payments;
      } else {
        // get application_id of selected loan
        const selectedLoan = this.loans.find(
          (loan: Loan) => loan.ac_deal_id == this.loanSelected
        );
        return this.payments.filter(
          (p: Payment) => p.application_id == selectedLoan.application_id
        ).length;
      }
    },
  },
  methods: {
    localizeDate(timestamp: number) {
      return localizeDate(timestamp, this.$i18n.locale);
    },
    proceedToPayment(payment: Payment) {
      this.$emit("executePayment", payment);
    },
    checkLoanId(data: any) {
      if (data !== null) {
        this.loanSelected = data;
      }
    },
  },
});
