<script>
import { HorizontalBar } from "vue-chartjs";
import Vue from "vue";

export default Vue.extend({
  name: "HorizontalBarChart",
  extends: HorizontalBar,
  props: {
    data: { type: Object, default: undefined },
    options: { type: Object, default: undefined },
  },
  watch: {
    data: {
      handler: function () {
        this.renderChart(this.data, this.options);
      },
    },
    options: {
      handler: function () {
        this.renderChart(this.data, this.options);
      },
    },
    immediate: true,
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
});
</script>
