






import Vue from "vue";

export default Vue.extend({
  name: "SquareButton",
  props: {
    disabled: { type: Boolean, default: false },
  },
});
